import { Link } from "gatsby"
import React, { Component, ReactElement } from "react"
import { connect } from "react-redux"
import LogoBlack from "../../images/logo_black.png"
import {
  Language,
  utilsStateInterface,
} from "../../store/reducers/utilsReducer"
import { localeContent } from "../../utils/locale"

interface Props {
  utilStore: utilsStateInterface
}

const navigation = {
  social: [
    {
      name: "Facebook",
      href: "https://facebook.com/getarusoil",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/arus_oil",
      icon: (props: any) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fill-rule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clip-rule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Mail",
      href: "mailto:info@arusoil.com",
      icon: (props: any) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          {...props}
        >
          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
        </svg>
      ),
    },
    {
      name: "Phone",
      href:
        "https://api.whatsapp.com/send?phone=60137311007&text=Hi%20Arus%20Oil%20Hotline%21%20I%20would%20like%20to%20request%20for%20your%20support.",
      icon: (props: any) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          {...props}
        >
          <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
        </svg>
      ),
    },
  ],
}

class Footer extends Component<Props> {
  renderFooterComponent = () => {
    let localNavigation = []
    const navigation = [
      { name: "About Us", href: "/aboutUs" },
      { name: "How To Recycle", href: "/recycleUCO" },
      { name: "How To Refer Friends", href: "/referralFaqs" },
      { name: "NGO Initiatives", href: "/ngoInitiative" },
      { name: "JMB Initiatives", href: "/jmbInitiative" },
      { name: "Carbon Savings", href: "/carbonSavings" },
      { name: "Terms & Conditions", href: "/termscondition" },
      { name: "Privacy Policy", href: "/privacypolicy" },
    ]

    const navigationBM = [
      { name: "Tentang Kami", href: "/bm/aboutUs" },
      { name: "Cara Mengitar Semula", href: "/bm/recycleUCO" },
      { name: "Cara Merujuk Rakan", href: "/bm/referralFaqs" },
      { name: "Inisiatif NGO", href: "/bm/ngoInitiative" },
      { name: "Inisiatif JMB", href: "/bm/jmbInitiative" },
      { name: "Penjimatan Karbon", href: "/bm/carbonSavings" },
      { name: "Terma dan Syarat", href: "/bm/termscondition" },
      { name: "Dasar Privasi", href: "/bm/privacypolicy" },
    ]

    const navigationCH = [
      { name: "关于我们", href: "/ch/aboutUs" },
      { name: "再循环指南", href: "/ch/recycleUCO" },
      { name: "转介朋友", href: "/ch/referralFaqs" },
      { name: "非政府组织倡议", href: "/ch/ngoInitiative" },
      { name: "JMB 倡议", href: "/ch/jmbInitiative" },
      { name: "节碳指南", href: "/ch/carbonSavings" },
      { name: "附带条约", href: "/ch/termscondition" },
      { name: "隐私政策", href: "/ch/privacypolicy" },
    ]

    if (this.props.utilStore.language === Language.EN) {
      localNavigation = navigation
    } else if (this.props.utilStore.language === Language.BM) {
      localNavigation = navigationBM
    } else {
      localNavigation = navigationCH
    }
    let footerList: ReactElement[] = []

    localNavigation.map(eachNavigation => {
      footerList.push(
        <li>
          <Link to={eachNavigation.href}>
            <p className="cursor-pointer text-base underline text-gray-500 hover:text-gray-900">
              {eachNavigation.name}
            </p>
          </Link>
        </li>
      )
      return null
    })

    return footerList
  }

  render() {
    const { language } = this.props.utilStore

    return (
      <footer className="bg-white border-t" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="grid grid-cols-2 gap-8 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold tracking-wider uppercase">
                    {localeContent(language).footer.legal}
                  </h3>
                  <ul className="mt-4 space-y-4">
                    {this.renderFooterComponent()}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-12 space-y-8 xl:col-span-1 xl:mt-0">
              <img className="h-12" src={LogoBlack} alt="Arus Oil" />
              <p className="text-gray-500 text-base">
                {localeContent(language).footer.vision}
              </p>
              <div className="flex space-x-6">
                {navigation.social.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              {localeContent(language).footer.copyright}
            </p>
          </div>
        </div>
      </footer>
    )
  }
}

const mapStateToProps = (state: any) => {
  return {
    utilStore: state.utilsStore,
  }
}

export default connect(mapStateToProps)(Footer)
